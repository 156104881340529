import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "schematic"
    }}>{`Schematic`}</h1>
    <h2 {...{
      "id": "core-components"
    }}>{`Core Components`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.668711656441715%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlUlEQVQoz01SyXLbMAzV//9RL/2AHnqN287UdSd2HC9SLIkklgegAyrpBMMDOCDwFnAwc8AbCYtSk7KC2YEgNYG1ZoDXakRWiqm6qrfqrWVp0NZeDwcCmsj48mLQiFAdb9ddq+QR7l5qgSF6nlVo9GRArcfdD3UvRLfzWYTdQ/V4/P11vT88gttyv35fHpNZtrgZrauJZPM03v7++tkAAl73e1fxCOi8zCfA+yBhWWF5MUvETifPUB5vpz97BhaR590ORBFhijpfFLK9pUbEHBFA2gELZmO2IVSvx5NA2ez49IRWI6KM1/vhS50nt8QR5rSxA5rodD5wLUnbAK4VgMGUOT1JYa6aUGZpjIhwa9EJS62X3bf6NmVzty1fbzJEnMg6q9wHUao0C9GgXOHm93sMEUGttdIim13N3it91oYcHw4B3j13ZhPxAdDxfFqWmVUbEbEQUeHK0nV2oVTKfL0gcXMQmLehg7mzsEJTGDOx1FrWuiy1xLabHlKKwTZapRTv2RCfwz2d++j4/6U+MffNtk3zPx+Pvd6oy+CAAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Core Components",
          "title": "Core Components",
          "src": "/static/3e97ff162eba328ff7ae7618808c6719/a6d36/schematic_nice60.png",
          "srcSet": ["/static/3e97ff162eba328ff7ae7618808c6719/222b7/schematic_nice60.png 163w", "/static/3e97ff162eba328ff7ae7618808c6719/ff46a/schematic_nice60.png 325w", "/static/3e97ff162eba328ff7ae7618808c6719/a6d36/schematic_nice60.png 650w", "/static/3e97ff162eba328ff7ae7618808c6719/e548f/schematic_nice60.png 975w", "/static/3e97ff162eba328ff7ae7618808c6719/3c492/schematic_nice60.png 1300w", "/static/3e97ff162eba328ff7ae7618808c6719/3af77/schematic_nice60.png 2071w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "matrix"
    }}>{`Matrix`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.68711656441718%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQUlEQVQozx2SiQ7bIBBE/f//2Daxgb1YTpP4piJohRgGpMcOU3+e3vteylHXo9a91GNtQ65tS2msc95L3WvdUz5+817KnvOW8tR7P68blCUqKqGKRHWeeEj2Ua2g82PfeabgrSeJagRCib/LrSksCZ2iCW5JDOLemUDBBLAKRuw7CQYwkdywGNm81pqm67sFZ0ABPIzjOFOUGV4YyLF1Yp13L3hRYEsGAw9L0bGr38/Un/vbPqDEKiCAyqxsxQ2pRJ6coBWgIKhMnowHDYqeQk0D+6h10P4Ig5kTAy//CmGwc3QmgpHlX2YMdskMYv4V5mRNrXE6Px8FO0eaA84BX95C9n94tomXgG+FJdIfXmySt4JJ/FeW3JIvKX3br2HbDrB4dAgLoxVyALMyIhpBB25GmD0Dg2W0Dpfee1Ifkp/u86zjLVbJClt2c1AG8zd4ZDRCRsi6IUnQqADScvdeGNt3ne59z6pvRaP4Vvf2DpN/iXWRZwWrtCi9xLk4sDH7utXnuX2Ouv4+yUgLbCYMZCO6VbQSryIB3cpSmQtS9T6CXXM8nqP3XkVKDiPn6IwV69habw28S4lMlgKhWO8pRmF2HHiBWdaY9vXqV/CcP2167rvVikE4iBNMrTy9X8/Fwa9be/pzPdf93JoCjJwZlZ/+xJJzLQN7rzUDFKQMsLXWe3/uuwCcx9l/47nvlSU7V4gK0cBG/K7rdLaWwOZPSWtKa2r757zP7fimGrbrOH61HVtuw82tpJbP5/p81vO6/gNwGUUCBdE/xgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Matrix",
          "title": "Matrix",
          "src": "/static/295450e688c62b44f5671d39e3cc303a/a6d36/schematic_nice60_matrix.png",
          "srcSet": ["/static/295450e688c62b44f5671d39e3cc303a/222b7/schematic_nice60_matrix.png 163w", "/static/295450e688c62b44f5671d39e3cc303a/ff46a/schematic_nice60_matrix.png 325w", "/static/295450e688c62b44f5671d39e3cc303a/a6d36/schematic_nice60_matrix.png 650w", "/static/295450e688c62b44f5671d39e3cc303a/e548f/schematic_nice60_matrix.png 975w", "/static/295450e688c62b44f5671d39e3cc303a/46e51/schematic_nice60_matrix.png 1003w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "rgb"
    }}>{`RGB`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.263803680981596%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABSUlEQVQY00XR22rbQBAGYL3/GyWFGEovTCApFHqTurGjs7SWbGml3ZnZORTZTTtXHz/8MMxkqmoiSByZWFNAjAnvCASsfAP+hyW/BiA2s0yYfVOVp+dxOUcMbf3dXRtgbMuX7lKhUFO+9JcKmJry9XytIqFrfwyXWswyMyN/bY4Pw1BMYe3yL647esLm9NC1B09YHx9d+2vD6fHcvU1AffnU1z8DayYqasYiSRMJEdMnUpLP5Aa8IWmawwoJtrUxQUIIMS4USciH1WNISj6u84bkwx3/EpqWeUXcykCwuLY4fhu9CxDa+rkfK0hYF/tuKICpLvb9WMREdb53lyIguvZ1GApRy8QsMXT5bhyrKS59uTu7j5mgzZ9c/+4Jmnzn+t8zbhjcYQLsq69jdwh8O5iZqZiaqqnwXzDb9kVTFt1mS/SeALDI1voDUuzHBERtFngAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "RGB",
          "title": "RGB",
          "src": "/static/bb3dea2a2459b514e72da82f9b2d085d/a6d36/schematic_nice60_rgb.png",
          "srcSet": ["/static/bb3dea2a2459b514e72da82f9b2d085d/222b7/schematic_nice60_rgb.png 163w", "/static/bb3dea2a2459b514e72da82f9b2d085d/ff46a/schematic_nice60_rgb.png 325w", "/static/bb3dea2a2459b514e72da82f9b2d085d/a6d36/schematic_nice60_rgb.png 650w", "/static/bb3dea2a2459b514e72da82f9b2d085d/e548f/schematic_nice60_rgb.png 975w", "/static/bb3dea2a2459b514e72da82f9b2d085d/a13c9/schematic_nice60_rgb.png 1178w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      